import React, { useEffect, useState, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  LinkCard,
  LinkCardBody,
  LinkCardGroup,
  LinkCardHeader,
  LinkCardMedia,
  LinkCardText,
  PageTitle,
  Spinner,
  Alert,
} from '@gsa/afp-component-library';
import {
  useAppAbility,
  useCurrentUser,
  VehicleSearch,
} from '@gsa/afp-shared-ui-utils';
import { StandardPageDescription } from '../../components/Forms/standard-page-description';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_VENDORS_BY_PERMISSION,
} from '../../services/data-layer';
import { homeLinks } from './home-links';
import './home.scss';
import { useFeature } from '../../utilities/features';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import { isUserTypeIdVendor, isGSAEmployee } from '../../utilities/user';
import {
  AlertNotificationProvider,
  AlertNotifications,
  useNotifications,
} from '../../components/StepperFlow/components/AlertNotifications';

const hasDraftVendors = (vendorList) =>
  vendorList?.length > 0 &&
  vendorList?.some((vndr) => vndr.fleetStatus === 'Draft');

const hasInactiveVendors = (vendorList) =>
  vendorList?.length > 0 &&
  vendorList?.some((vndr) => vndr.fleetStatus === 'Inactive');

const hasUnconfirmedVendors = (vendorList) =>
  vendorList?.length > 0 && vendorList?.some((vndr) => !vndr.isConfirmed);

const HomePage = () => {
  const { setNotification } = useNotifications();
  const features = useFeature();
  const isFedFmsActive = () => features.fedfms;
  const history = useHistory();

  const handleCompleteProfile = useCallback(
    (id) => () => {
      history.push(`/vendor/details/${id}`);
    },
    [],
  );

  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();
  const [links, setLinks] = useState([]);
  const userType = currentUser?.userType?.id;

  const setNotifications = (vendorList) =>
    vendorList?.length > 0 &&
    vendorList?.forEach((vndr) => {
      if (vndr.fleetStatus === 'Draft') {
        setNotification({
          id: vndr.fleetVendorNumber,
          message: `You have limited access within GSA Fleet. Please complete your Vendor Company Profile ${vndr.vendorName}`,
          type: 'error',
          heading: 'Incomplete Vendor Company Profile',
          showClose: true,
          actions: (
            <Button
              className="margin-left-8 margin-y-2"
              label="Complete Profile"
              id={vndr.id}
              onClick={handleCompleteProfile(vndr.id)}
            />
          ),
        });
      }
    });

  const setHomeLinks = (fedFmsActive = false) => {
    const linkArray = homeLinks.filter((c) => c.userTypes.includes(userType));
    if (!fedFmsActive) {
      // eslint-disable-next-line
      linkArray.forEach(
        // eslint-disable-next-line no-return-assign
        (l) =>
          // eslint-disable-next-line no-param-reassign
          (l.cards = l.cards.filter(
            (c) =>
              !['Agency owned vehicle mileage history', 'Agency owned vehicle expenses'].includes(
                c.title,
              ),
          )),
      );

      // if user is not internal user and not fedFmsActive we will filter
      if (!isGSAEmployee(userType))
        linkArray.forEach(
          // eslint-disable-next-line no-return-assign
          (l) =>
            // eslint-disable-next-line no-param-reassign
            (l.cards = l.cards.filter(
              (c) =>
                !['Vehicle recalls', 'Vehicle reports manager'].includes(
                  c.title,
                ),
            )),
        );
    }
    if (userType !== '3') {
      linkArray.forEach(
        // eslint-disable-next-line no-return-assign
        (l) =>
          // eslint-disable-next-line no-param-reassign
          (l.cards = l.cards.filter(
            (c) => !['Vehicle Modification Overview'].includes(c.title),
          )),
      );
    }
    if (userType !== '3') {
      linkArray.forEach(
        // eslint-disable-next-line no-return-assign
        (l) =>
          // eslint-disable-next-line no-param-reassign
          (l.cards = l.cards.filter(
            (c) => !['Vehicle Modification Overview'].includes(c.title),
          )),
      );
    }
    setLinks(linkArray);
  };

  const [getAgenciesByPermission, { loading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const fedFmsActive =
          data?.getAgenciesByPermission.length > 0 && isFedFmsActive();
        setHomeLinks(fedFmsActive);
      },
    },
  );

  const { vendorLoading, data: getVendorsByPermissionData } = useQuery(
    GET_VENDORS_BY_PERMISSION,
    {
      variables: {
        operation: OPERATIONS.View,
        subject: SUBJECTS.Vendor,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: !isUserTypeIdVendor(userType),
    },
  );

  useEffect(() => {
    if (ability.can('view', 'Vehicle')) {
      getAgenciesByPermission({
        variables: {
          operation: 'view',
          subject: 'Vehicle',
          feature: 'Basic',
        },
      });
    } else if (ability.can('view', 'Tag')) {
      getAgenciesByPermission({
        variables: {
          operation: 'view',
          subject: 'Tag',
          feature: 'Basic',
        },
      });
    } else {
      setHomeLinks();
    }
  }, [currentUser, ability, userType, homeLinks]);

  useEffect(() => {
    // Set the notifications
    if (
      getVendorsByPermissionData?.getVendorsByPermission &&
      getVendorsByPermissionData?.getVendorsByPermission.length > 0
    ) {
      setNotifications(getVendorsByPermissionData?.getVendorsByPermission);
    }
  }, [getVendorsByPermissionData]);

  const replaceLinkTemplateStringExpressions = (str) => {
    let result = str;
    if (typeof window !== 'undefined' && window) {
      result = result.replace(/#\{appURLs\.(.+)\}/g, (_match, capture) => {
        return window.AFP_CONFIG.appURLs[capture];
      });
    }
    return result;
  };

  const shouldRedirectToAdmin =
    hasDraftVendors(getVendorsByPermissionData?.getVendorsByPermission) &&
    ability.can(OPERATIONS.View, SUBJECTS.AdminConsole);
  const showConfirmVendorAlert =
    isUserTypeIdVendor(userType) &&
    hasUnconfirmedVendors(getVendorsByPermissionData?.getVendorsByPermission);
  const showInactiveVendorAlert =
    isUserTypeIdVendor(userType) &&
    hasInactiveVendors(getVendorsByPermissionData?.getVendorsByPermission);
  const showDraftVendorAlert =
    isUserTypeIdVendor(userType) &&
    hasDraftVendors(getVendorsByPermissionData?.getVendorsByPermission);

  return (
    <>
      {shouldRedirectToAdmin && <Redirect to="/admin" />}
      {showConfirmVendorAlert && (
        <Alert
          slim
          type="warning"
          className="margin-top-2"
          data-testid="vendor-profile-review-alert"
        >
          Your company profile(s) needs review.{' '}
          <a href="/profile">
            Please review and/or update your company profile to get started
          </a>
          .
        </Alert>
      )}
      {showDraftVendorAlert && <AlertNotifications />}
      {showInactiveVendorAlert && (
        <Alert
          slim
          type="warning"
          className="margin-top-2"
          data-testid="vendor-profile-inactive-alert"
        >
          Your company(s) is not in active status.{' '}
          <a href="/profile">
            Please review the company(s) details and contact a GSA point of
            contact to activate your company
          </a>
          .
        </Alert>
      )}
      <PageTitle title="Get Started" />
      <div className="grid-row margin-1px">
        <div className="grid-col-7">
          <StandardPageDescription>
            Here are some links to get you started. Please keep an eye out for
            new features and updates as we will roll them out regularly.
            <div className="text-bold margin-top-2">Your voice matters.</div>
            <span>
              <a
                href="https://feedback.gsa.gov/jfe/form/SV_9WEuyv6ufF4wv3M"
                target="_blank"
                rel="noreferrer"
              >
                Help us improve GSAFleet.gov by taking a&nbsp;quick survey.
              </a>
            </span>
          </StandardPageDescription>
        </div>
        <div className="grid-col-5">
          <VehicleSearch />
        </div>
      </div>

      {loading || vendorLoading ? (
        <Spinner aria-busy="true" className="loading_backdrop" size="small" />
      ) : (
        links.map((c) => {
          const visibleCards = c.cards.filter(
            (card) => !card.rule || card.rule(ability, currentUser),
          );
          if (!visibleCards.length) return null;

          return (
            <React.Fragment key={c.header}>
              <h2 className="text-uppercase">{c.header}</h2>
              <LinkCardGroup>
                {visibleCards.map((d) => (
                  <LinkCard key={d.title} noMedia>
                    <LinkCardMedia />
                    <LinkCardBody>
                      <LinkCardHeader titleCasing>
                        <a href={replaceLinkTemplateStringExpressions(d.link)}>
                          {d.title}
                        </a>
                      </LinkCardHeader>
                      <LinkCardText>
                        <span className="home__card-height">
                          {d.description}
                        </span>
                      </LinkCardText>
                    </LinkCardBody>
                  </LinkCard>
                ))}
              </LinkCardGroup>
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

export default () => (
  <AlertNotificationProvider>
    <HomePage />
  </AlertNotificationProvider>
);
