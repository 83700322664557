/* eslint-disable react/prop-types */
import React from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
} from '@gsa/afp-component-library';
import { HELP_SIDENAV_MAPPING } from './help-sidenav-mapping';

const HelpSection = ({ activeItem }) => {
  const { currentUser } = useCurrentUser();
  const { description, cards, title, columns } =
    HELP_SIDENAV_MAPPING[activeItem];
  const filteredCards = cards?.filter(
    (card) => !card.rule || card.rule(currentUser),
  );

  return (
    <div>
      <h2 className="border-bottom-05 border-primary-lighter padding-bottom-1 margin-top-5 tablet:margin-top-0">
        {title}
      </h2>
      <div className="grid-row margin-bottom-4 grid-gap-2">
        <div className="tablet:grid-col-8">
          {description.leftSide && description.leftSide}
        </div>
        <div className="tablet:grid-col-4">
          {description.rightSide && description.rightSide}
        </div>
      </div>
      <div className="grid-row">
        {filteredCards?.length > 0 &&
          filteredCards.map(({ header, body, actions, props }) => (
            <Card.Card
              className={`tablet:grid-col-${
                columns || (filteredCards.length > 2 ? '4' : '6')
              }`}
              key={header}
            >
              <Card.CardHeader>{header}</Card.CardHeader>
              <Card.CardBody>{body}</Card.CardBody>
              <Card.CardFooter>
                {props?.type === 'multipleVideos' && (
                  <ButtonDropdown
                    label={props?.label}
                    variant="outline"
                    className=" width-card-lg display-flex margin-top-2"
                    side="center"
                  >
                    {props?.items.map((item) => (
                      <a href={item?.url} target="blank" key={item?.url}>
                        <ButtonDropdownItem
                          key={item?.label}
                          label={item?.label}
                          iconName=""
                        />
                      </a>
                    ))}
                  </ButtonDropdown>
                )}

                {actions?.map(({ url, label, variant, leftIcon }) => (
                  <a href={url} target="blank" key={url}>
                    <Button
                      label={label}
                      variant={variant}
                      className="usa-button width-full margin-top-2"
                      leftIcon={leftIcon}
                    />
                  </a>
                ))}
              </Card.CardFooter>
            </Card.Card>
          ))}
      </div>
    </div>
  );
};

HelpSection.propTypes = {
  activeItem: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

HelpSection.defaultProps = {
  items: [],
};

export default HelpSection;
