import { USER_TYPE_ID } from './constants';

export const isUserTypeIdVendor = (userTypeId) =>
  userTypeId === USER_TYPE_ID.Vendor;

export const isUserTypeVendor = (userType) => isUserTypeIdVendor(userType?.id);

export const isUserVendor = (user) => isUserTypeVendor(user?.userType);

export const isGSAEmployee = (userTypeId) => userTypeId === USER_TYPE_ID.GSA;

export const isCustomerUser = (userTypeId) => userTypeId === USER_TYPE_ID.Customer;
